require('./bootstrap');


$(document).on('click', 'a[href^="#"]',function () {
    $('html, body').animate({
        scrollTop: $('#' + $.attr(this, 'href').substr(1)).offset().top
    }, 500);

    return false;
});


/**** COOKIES */

// --- Config --- //
var purecookieTitle = ""; // Title
var purecookieDesc = "Cookies Este site utiliza Cookies para que a sua navegação seja mais agradável e eficiente. Caso continue a navegar neste site, tomaremos por si aceite a instalação de Cookies. "; // Description
var purecookieLink = '<a href="https://www.fidelidade.pt/PT/protecao-dados/Documents/Politica_de_Cookies%E2%80%93FIDELIDADE.pdf" class="banner-policy-link" tabindex="1" aria-label="Para saber mais sobre a forma como utilizamos os Cookies ou como poderá gerir ou desactivar, aceda aqui à nossa Política de Cookies.">Para saber mais sobre a forma como utilizamos os Cookies ou como poderá gerir ou desactivar, aceda aqui à nossa Política de Cookies.</a>'; // Cookiepolicy link
var purecookieButton = "ENTENDI"; // Button text
// ---        --- //


function pureFadeIn(elem, display) {
    var el = document.getElementById(elem);
    el.style.opacity = 0;
    el.style.display = display || "block";

    (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val += .02) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
};
function pureFadeOut(elem) {
    var el = document.getElementById(elem);
    el.style.opacity = 1;

    (function fade() {
        if ((el.style.opacity -= .02) < 0) {
            el.style.display = "none";
        } else {
            requestAnimationFrame(fade);
        }
    })();
};

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}

purecookieDismiss = function() {
    setCookie('purecookieDismiss', '1', 7);
    pureFadeOut("cookieConsentContainer");
}
function cookieConsent() {
    if (!getCookie('purecookieDismiss')) {
        document.body.innerHTML += '<div class="cookieConsentContainer" id="cookieConsentContainer"><div class="cookieDesc"><p>' + purecookieDesc + ' ' + purecookieLink + '</p></div><div class="cookieButton"><a onClick="purecookieDismiss();">' + purecookieButton + '</a></div></div>';
        pureFadeIn("cookieConsentContainer");
    }
}


window.onload = function () { cookieConsent(); };
